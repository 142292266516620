import * as React from "react"
import { Box } from "@chakra-ui/react";
import type { HeadFC } from "gatsby"
import WithSubnavigation from "../components/Navigation";

const IndexPage = () => {
  return (
    <>
      <Box>
        現在サイトの準備中です
      </Box>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
